import React from 'react';
import { graphql } from 'gatsby';
import DzialaniePage from '../components/DzialaniePage';
export default props => {
	return <DzialaniePage {...props} />;
};

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			fields {
				wiedza {
					frontmatter {
						title
						slug
						category
						subtitle
						thumbnail {
							childImageSharp {
								sizes(maxWidth: 800) {
									src
									srcSet
								}
							}
						}
						image {
							childImageSharp {
								sizes(maxWidth: 800) {
									src
									srcSet
								}
							}
						}
					}
				}
			}
			frontmatter {
				title
				subtitle
				purpose
				participiants
				category
				materials
				duration
				slug
				image {
					childImageSharp {
						sizes(maxWidth: 1100) {
							src
							srcSet
						}
					}
				}
			}
		}
	}
`;
