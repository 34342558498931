import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { activityPrimary } from '../contants/colors';
import Layout from '../components/layout';
import IconParticipant from '../components/IconParticipant';
import IconMaterials from '../components/IconMaterials';
import IconClock from '../components/IconClock';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import TopBar from '../components/TopBar';
import PageCreativeCommon from '../components/PageCreativeCommon';
import Tile from '../components/Tile';
import IconArrowRight from '../components/IconArrowRight';
import { getPageUrl } from '../utils';

const StyledContainer = styled('div')`
	border-top: 6px solid ${activityPrimary};

	*::selection {
		background: ${activityPrimary};
		color: #000;
	}

	p a {
		color: ${activityPrimary};
	}

	@media print {
		border-top: 0;
	}
`;

const StyledInnerContainer = styled('div')`
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 2.5em;
`;

const StyledPurpose = styled('p')`
	font-weight: 200;
	font-size: 1.7em;
	max-width: 850px;
	margin: 1em auto;
	line-height: 1.3em;

	@media print {
		font-size: 1em;
	}
`;

const StyledActivityMeta = styled('div')`
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	padding: 60px 0;
	margin: 60px 0;

	@media print {
		padding: 30px 0;
		margin: 30px 0;
	}
`;

const StyledContent = styled('div')`
	max-width: 850px;
	margin: 0 auto;
	font-size: 1.2em;
	line-height: 1.4em;

	h2,
	h3,
	h4,
	h5 {
		margin: 3em 0 1.5em;
		font-family: 'Work Sans', sans-serif;
	}

	li {
		margin-top: 1.2em;
	}

	@media print {
		img,
		.gatsby-resp-image-link {
			display: none !important;
		}
	}
`;

const StyledIconWrapper = styled('span')`
	display: inline-block;
	padding: 0 0.5em;

	@media print {
		display: none;
	}
`;

const StyledMetaLabel = styled('div')`
	font-family: 'Work Sans', sans-serif;
	flex-shrink: 0;
	font-weight: bold;
	font-size: 1.2em;
	display: flex;
	width: 240px;
`;

const StyledMetaRow = styled('div')`
	display: flex;

	@media (max-width: 580px) {
		flex-direction: column;
	}

	@media print {
		flex-direction: column;
	}
`;

const StyledMetaValue = styled('div')`
	border-left: 2px solid #000;
	padding-bottom: 2em;
	padding-left: 2em;

	@media (max-width: 580px) {
		border-left: 0;
		margin-top: 1em;
		padding-left: 0;
	}

	@media print {
		padding-bottom: 1em;
		border-left: 0;
	}
`;

const StyledMaterialItem = styled('li')`
	border-bottom: 1px solid #000;

	@media print {
		margin-bottom: 0.3em;
		border-bottom: 0;
	}
`;

const StyledArticle = styled('article')`
	display: flex;
`;

const StyledArticleContent = styled('div')`
	flex-grow: 1;
`;

const commonNavigationStyle = css`
	color: #000;
	text-decoration: none;
	display: flex;
	padding: 2em 1.5em;
	position: fixed;
	top: 30%;

	@media (max-width: 990px) {
		padding: 2em 0.5em;
	}

	@media (max-width: 480px) {
		position: absolute;
	}

	@media print {
		display: none;
	}
`;

const StyledImageContainer = styled('div')`
	margin: 0 auto;
	text-align: center;

	@media print {
		display: none;
	}

	img {
		max-height: 670px;
	}
`;

const StyledPrevious = styled(Link)`
	${commonNavigationStyle};
	writing-mode: vertical-lr;
	color: #000;
	left: 0;
`;

const StyledNext = styled(Link)`
	${commonNavigationStyle};
	writing-mode: vertical-lr;
	color: #000;
	right: 0;
`;

const StyledDzialaniaContainer = styled('div')`
	max-width: 850px;
	margin: 3em auto;

	@media print {
		display: none;
	}
`;

const StyledHeader = styled('h3')`
	margin: 3em 0;
`;

const StyledRightArrowWrapper = styled('div')`
	margin-bottom: 1em;
	transform: scale(0.8);
`;

const StyledLeftArrowWrapper = styled('div')`
	margin-bottom: 1em;
	transform: scale(0.8) rotate(180deg);
`;

const StyledTilesContainer = styled('div')`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	text-align: center;

	a {
		color: #000;
	}
`;

const StyledTileContainer = styled('div')`
	width: 45%;
	margin-bottom: 2em;

	@media (max-width: 480px) {
		width: 100%;
	}
`;

export default ({ data, pageContext: { prev, next } }) => {
	const {
		frontmatter: {
			category,
			title,
			subtitle,
			slug,
			purpose,
			participiants,
			image,
			materials,
			duration
		},
		html,
		htmlPreview,
		fields: { wiedza } = {}
	} = data.markdownRemark;
	return (
		<Layout>
			<Helmet>
				<html lang="pl" />
				<title>{title} - do dizajnu</title>
				<meta name="description" content={purpose} />
				<meta property="og:description" content={purpose} />
				<meta
					property="og:url"
					content={`https://dodizajnu.pl${getPageUrl(
						category,
						slug
					)}`}
				/>
				<meta property="fb:app_id" content="328106031106735" />
				<meta property="og:type" content="article" />
				<meta property="og:locale" content="pl_PL" />
				<meta
					property="og:title"
					content={`${title} - ${subtitle} - do dizajnu`}
				/>
				{image && (
					<meta
						property="og:image"
						content={`https://dodizajnu.pl${
							image.childImageSharp.sizes.src
						}`}
					/>
				)}
			</Helmet>
			<StyledContainer>
				<TopBar />
				<StyledArticle>
					{prev && (
						<StyledPrevious to={`/${prev.fields.slug}`}>
							<StyledLeftArrowWrapper>
								<IconArrowRight />
							</StyledLeftArrowWrapper>
							{prev.frontmatter.title}
						</StyledPrevious>
					)}
					<StyledArticleContent>
						<PageHeader
							title={title}
							category={category}
							subtitle={subtitle}
							primaryColor={activityPrimary}
						/>
						<div>
							<StyledInnerContainer>
								<StyledPurpose>{purpose}</StyledPurpose>
								{image && (
									<StyledImageContainer>
										<img
											alt=""
											src={
												image.childImageSharp.sizes.src
											}
											srcSet={
												image.childImageSharp.sizes
													.srcSet
											}
										/>
									</StyledImageContainer>
								)}
							</StyledInnerContainer>
						</div>
						<StyledActivityMeta>
							<StyledInnerContainer>
								<StyledMetaRow>
									<StyledMetaLabel>
										<StyledIconWrapper>
											<IconParticipant />
										</StyledIconWrapper>
										Uczestnicy
									</StyledMetaLabel>
									<StyledMetaValue>
										{participiants}
									</StyledMetaValue>
								</StyledMetaRow>
								<StyledMetaRow>
									<StyledMetaLabel>
										<StyledIconWrapper>
											<IconClock />
										</StyledIconWrapper>
										Czas trwania
									</StyledMetaLabel>
									<StyledMetaValue>
										{duration}
									</StyledMetaValue>
								</StyledMetaRow>
								<StyledMetaRow>
									<StyledMetaLabel>
										<StyledIconWrapper>
											<IconMaterials />
										</StyledIconWrapper>
										Materiały
									</StyledMetaLabel>
									<StyledMetaValue>
										<ul>
											{materials.map(
												(material, index) => (
													<StyledMaterialItem
														key={index}
													>
														{material}
													</StyledMaterialItem>
												)
											)}
										</ul>
									</StyledMetaValue>
								</StyledMetaRow>
							</StyledInnerContainer>
						</StyledActivityMeta>
						<StyledInnerContainer>
							<StyledContent>
								{html && (
									<div
										dangerouslySetInnerHTML={{
											__html: html
										}}
									/>
								)}
								{htmlPreview}
							</StyledContent>
							{wiedza && (
								<StyledDzialaniaContainer>
									<StyledHeader>Więcej</StyledHeader>
									<StyledTilesContainer>
										{wiedza.map(_wiedza => (
											<StyledTileContainer
												key={_wiedza.frontmatter.slug}
											>
												<Tile
													size="relative"
													{..._wiedza.frontmatter}
												/>
											</StyledTileContainer>
										))}
									</StyledTilesContainer>
								</StyledDzialaniaContainer>
							)}
							<PageCreativeCommon />
						</StyledInnerContainer>
					</StyledArticleContent>
					{next && (
						<StyledNext to={`/${next.fields.slug}`}>
							<StyledRightArrowWrapper>
								<IconArrowRight />
							</StyledRightArrowWrapper>
							{next.frontmatter.title}
						</StyledNext>
					)}
				</StyledArticle>
				<Footer />
			</StyledContainer>
		</Layout>
	);
};
