import * as React from 'react';

const IconClock = () => (
	<svg viewBox="0 0 26 26" height="26px">
		<circle
			strokeWidth="2"
			stroke="#000"
			fill="none"
			cx="13"
			cy="13"
			r="12"
		/>
		<line
			strokeWidth="2"
			stroke="#000"
			fill="none"
			x1="13"
			x2="13"
			y1="14"
			y2="6"
		/>
		<line
			strokeWidth="2"
			stroke="#000"
			fill="none"
			x1="13"
			x2="22"
			y1="14"
			y2="14"
		/>
	</svg>
);

export default IconClock;
