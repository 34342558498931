import * as React from 'react';

const IconMaterials = () => (
	<svg height="28" viewBox="0 0 29 28">
		<path
			strokeWidth="2"
			stroke="#000"
			fill="none"
			d="M 1,8 L 7,2 L 13,10 L 8,15 L 1,8"
		/>
		<path
			strokeWidth="2"
			stroke="#000"
			fill="none"
			d="M 14,20 L 19,15 L 26,22 L 21,27 L 14,20"
		/>
		<path
			strokeWidth="2"
			stroke="#000"
			fill="none"
			d="M 3,21 L 18,5 L 23,10 L 8,26 L 3,21"
		/>
		<path
			strokeWidth="2"
			stroke="#000"
			fill="none"
			d="M 19,5 L 27,1 L 23,10"
		/>
		<line
			strokeWidth="1"
			stroke="#000"
			fill="none"
			x1="4"
			x2="6"
			y1="9"
			y2="7"
		/>
		<line
			strokeWidth="1"
			stroke="#000"
			fill="none"
			x1="6"
			x2="8"
			y1="11"
			y2="9"
		/>
		<line
			strokeWidth="1"
			stroke="#000"
			fill="none"
			x1="17"
			x2="19"
			y1="22"
			y2="20"
		/>
		<line
			strokeWidth="1"
			stroke="#000"
			fill="none"
			x1="19"
			x2="21"
			y1="24"
			y2="22"
		/>
		<line
			strokeWidth="2"
			stroke="#000"
			fill="none"
			x1="23"
			x2="25"
			y1="3"
			y2="5"
		/>
	</svg>
);

export default IconMaterials;
