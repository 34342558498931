import * as React from 'react';

const IconParticipant = () => (
	<svg viewBox="0 0 22 25" height="25px">
		<circle
			cx="11"
			cy="7"
			r="6"
			strokeWidth="2"
			stroke="#000"
			fill="none"
		/>
		<path
			strokeWidth="2"
			stroke="#000"
			fill="none"
			d="M 0,24 L 21,24 L 21,21 A 8,8 0,0,0 14,13 L 9,13 A 8,8 0,0,0 1,21 L 1,24"
		/>
	</svg>
);

export default IconParticipant;
